<template>
	<div class="page">
		<img class="closeImg" @click="$router.back()" src="../../assets/img/close.png"/>
		<div class="swiperBox">
			<swiper style="width:100%;height: 100%;overflow: hidden;position: relative;" ref="mySwiper" :options="swiperOptions">
				<swiper-slide v-for="(item,index) in petBanner" :key="index">
					<video
						v-if="item.url.substr(item.url.length-3)==='mp4'"
						controls
						autoplay
						loop
						muted
						style="width:100%;height: 100%;"
					>
						<source :src="item.url" type="video/mp4">
						Your browser does not support the video tag.
					</video>
					<img v-else style="width:100%;height: 100%;" :src="item.url"/>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				swiperOptions: {
					pagination: {
						el:'.swiper-pagination',
						type: 'fraction'
					},
				},
				
				petBanner:[],
			}
		},
		mounted(){
			this.petBanner = JSON.parse(sessionStorage.getItem('petBanner'))
			
			let index = this.$route.query.index
			let ss = this.$refs.mySwiper.swiper
			ss.slideTo(parseInt(index), 0, false)
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="scss">
.page{
	width:100%;height: 100%;background-color: #000;display: flex;align-items: center;position: relative;
	.closeImg{
		position: absolute;top:2rem;left:.2rem;width:.6rem;height: .6rem;
	}
	.swiperBox{
		width:100%;height: 4.46rem;background-color: #f5f5f5;
		.swiper-pagination{
			box-sizing: border-box;width:.6rem;background-color:rgba(150,150,150,.5);color:#fff;right:.15rem;left:auto;bottom:.15rem;border-radius: .05rem;padding:.05rem;
		}
	}
}	
</style>
